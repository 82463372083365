import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-columbia-missouri.png'
import image0 from "../../images/cities/scale-model-of-breakout-como-in-columbia-missouri.png"
import image1 from "../../images/cities/scale-model-of-columbia-audubon-nature-sanctuary-in-columbia-missouri.png"
import image2 from "../../images/cities/scale-model-of-shelter-gardens-in-columbia-missouri.png"
import image3 from "../../images/cities/scale-model-of-boone-county-history-&-culture-center-in-columbia-missouri.png"
import image4 from "../../images/cities/scale-model-of-flat-branch-park-in-columbia-missouri.png"
import image5 from "../../images/cities/scale-model-of-clyde-wilson-memorial-park-in-columbia-missouri.png"
import image6 from "../../images/cities/scale-model-of-highpointe-park-in-columbia-missouri.png"
import image7 from "../../images/cities/scale-model-of-columbia-convention-&-visitors-in-columbia-missouri.png"
import image8 from "../../images/cities/scale-model-of-department-of-parks,-recreation-and-tourism-in-columbia-missouri.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Columbia'
            state='Missouri'
            image={image}
            lat='38.9517053'
            lon='-92.33407239999997'
            attractions={ [{"name": "Breakout CoMo", "vicinity": "218 N 8th St, Columbia", "types": ["point_of_interest", "establishment"], "lat": 38.9542341, "lng": -92.3282648}, {"name": "Columbia Audubon Nature Sanctuary", "vicinity": "3607 Bray Ave, Columbia", "types": ["park", "point_of_interest", "establishment"], "lat": 38.9412531, "lng": -92.38953709999998}, {"name": "Shelter Gardens", "vicinity": "1817 W Broadway, Columbia", "types": ["park", "point_of_interest", "establishment"], "lat": 38.9565118, "lng": -92.36814909999998}, {"name": "Boone County History & Culture Center", "vicinity": "3801 Ponderosa St, Columbia", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 38.9074033, "lng": -92.2938034}, {"name": "Flat Branch Park", "vicinity": "300 S Providence Rd, Columbia", "types": ["park", "point_of_interest", "establishment"], "lat": 38.9489555, "lng": -92.33310230000001}, {"name": "Clyde Wilson Memorial Park", "vicinity": "601 Rockhill Rd, Columbia", "types": ["park", "point_of_interest", "establishment"], "lat": 38.9434705, "lng": -92.3126757}, {"name": "Highpointe Park", "vicinity": "801 Huntridge Dr, Columbia", "types": ["park", "point_of_interest", "establishment"], "lat": 38.9194536, "lng": -92.32975669999996}, {"name": "Columbia Convention & Visitors", "vicinity": "300 S Providence Rd, Columbia", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 38.9487124, "lng": -92.33389199999999}, {"name": "Department of Parks, Recreation and Tourism", "vicinity": "Columbia", "types": ["point_of_interest", "establishment"], "lat": 38.9424622, "lng": -92.32422939999998}] }
            attractionImages={ {"Breakout CoMo":image0,"Columbia Audubon Nature Sanctuary":image1,"Shelter Gardens":image2,"Boone County History & Culture Center":image3,"Flat Branch Park":image4,"Clyde Wilson Memorial Park":image5,"Highpointe Park":image6,"Columbia Convention & Visitors":image7,"Department of Parks, Recreation and Tourism":image8,} }
       />);
  }
}